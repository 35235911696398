@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    width: 343px;
    max-width: 100%;
    font-size: 16px;

    &.dark,
    &.dark path {
        color: $dark-purpure;
        stroke: $dark-purpure;
    }

    &.light,
    &.light path {
        color: $light-purpure;
        stroke: $light-purpure;
    }
}

.content {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-bottom: 1px solid $purpure;
}

.input {
    padding: 0;
    width: 100%;
    background: none;
    border: none;
    color: $white;
    font-size: inherit;
    font-family: $light;
    line-height: 160%;
    outline: none;

    &::placeholder {
        .container.dark & {
            color: $dark-purpure;
        }

        .container.light & {
            color: $light-purpure;
        }
    }
}
