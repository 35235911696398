@import 'assets/css/config';

.container {
    position: sticky;
    top: 0;
    background: $black;
    border-bottom: 1px solid $purpure;
    z-index: 15;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 1240px;
    width: 100%;
    font-size: 16px;
    line-height: 140%;
}

.leftBlock {
    display: flex;
    gap: 44px;
}

.links {
    display: flex;
    align-items: center;
    gap: 32px;
}

.rightBlock {
    display: flex;
    align-items: center;
    gap: 30px;
}

.user {
    display: flex;
    align-items: center;
    gap: 16px;
}

.userImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $white-purpure;
    color: $black;
    font-family: $semibold;
}

.logout {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 24px;
    border-top: 1px solid $dark-purpure;
}

.burgerMenu {
    align-self: stretch;
}

.burgerMenuIconContainer {
    display: flex;
    align-items: center;
    height: 100%;
}

.burgerMenuItem {
    color: $light-purpure !important;
}

@include screen-xs {
    .userName {
        display: none;
    }
}

@include screen-nmd {
    .burgerMenu {
        display: none;
    }
}

@include screen-md {
    .links {
        display: none;
    }
}
