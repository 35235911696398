@import 'assets/css/config';

.container {
    display: flex;
    gap: 60px;
    padding: 24px;
    border-radius: 24px;
    background: $shadow;
}

.textBlock {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.textBlockTop {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contactText {
    font-size: 14px;
    line-height: 160%;
}

.moreLink {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    line-height: 140%;
    color: $light-purpure;
    transition: $transition;

    path {
        stroke: $light-purpure;
        transition: $transition;
    }

    &:hover {
        color: $white;

        path {
            stroke: $white;
        }
    }
}

.formBlock {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 20px;
    width: 100%;
}

.formBlockFields {
    display: flex;
    align-items: center;
    gap: 20px;
}

.formBlockInput {
    width: auto;
}

.formBlockSubmit {
    display: flex;
    align-items: center;
    gap: 24px;
}

.formBlockSubmitBtn {
    &:after {
        background: $shadow !important;
    }
}

.privacy {
    font-size: 12px;
    line-height: 140%;
}

.privacyLink {
    font-size: inherit;
    color: $light-purpure !important;

    &:active {
        font-size: 11px;
    }
}

.sendedBlock {
    display: flex;
    align-self: center;
    gap: 8px;
    width: 100%;
    color: $light-purpure;
}

.sendedBlockIcon path {
    stroke: $detail-green;
}

.sendedBlockText {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 160%;
}

@include screen-nmd {
    .formBlockSubmitBtn.mobile,
    .moreLink.mobile {
        display: none;
    }
}

@include screen-md {
    .formBlockSubmitBtn.desktop,
    .moreLink.desktop {
        display: none;
    }

    .container {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }

    .textBlock {
        width: auto;
        text-align: center;
    }

    .formBlock {
        gap: 8px;
    }

    .formBlockInput {
        width: 100%;
    }

    .formBlockSubmit {
        justify-content: center;
    }

    .sendedBlock {
        align-self: center;
        width: auto;
    }

    .sendedBlockText {
        flex-direction: row;
        gap: 5px;
    }
}

@include screen-nsm {
    .moreLinkText.mobile {
        display: none;
    }
}

@include screen-sm {
    .moreLinkText.desktop {
        display: none;
    }

    .formBlock {
        gap: 16px;
    }

    .formBlockFields {
        flex-direction: column;
        gap: 24px;
    }

    .privacy {
        text-align: center;
    }

    .sendedBlockText {
        display: block;
    }
}
