@import 'assets/css/config';

.container {
    display: flex;
    gap: 12px;
}

.tag {
    position: relative;
    padding: 4px 12px;
    z-index: 1;
    font-size: 12px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $white-purpure;
        border-radius: 25px;
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 1px;
        right: 1px;
        left: 1px;
        bottom: 1px;
        background: $black;
        border-radius: 25px;
        z-index: -1;
    }
}
