@import 'assets/css/config';

.container {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 160%;
}

.light {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 39px;

    &.present {
        background: $detail-green;
    }

    &.future {
        background: $detail-orange;
    }

    &.past {
        background: $detail-red;
    }
}
