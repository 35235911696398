@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px;
    width: 100%;
    max-width: 1240px;
}

.anchor {
    position: absolute;
    margin-top: calc(-88px - 20px);
}

.title {
    align-self: flex-start;
    background: $white-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.eventTasks {
    max-width: 800px;

    ul {
        margin: 7px 0;
    }
}

.eventResult {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background: $blue-purpure;
    border-radius: 24px;
}

.markdown {
    * {
        margin: 0;
        font-family: $light;
        font-size: 20px;
        line-height: 160%;
    }

    pre {
        white-space: pre-wrap;
    }
}
