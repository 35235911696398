@import 'assets/css/config';

.container {
    position: relative;
}

.backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    .container.active & {
        display: block;
    }
}

.items {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: 4px 0;
    top: calc(100% + 10px);
    right: 0;
    max-height: 0;
    opacity: 0;
    z-index: 2;
    overflow: hidden;
    background: $shadow;
    border-radius: 4px;
    box-shadow: 0px 10px 16px rgba(63, 59, 110, 0.5);
    transition: $transition;

    .container.active & {
        opacity: 1;
        max-height: 320px;
    }
}

.item {
    padding: 12px 16px !important;
    width: max-content;
    min-width: 100%;
    text-align: left;
    color: $white !important;
}
