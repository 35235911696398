@import 'assets/css/config';

.eventBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 88px;
    margin: 44px 0;
}

@include screen-lg {
    .eventBody {
        gap: 44px;
    }
}
