@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
}

.event {
    display: flex;
    gap: 16px;
    padding: 24px;
    border: 1px solid $purpure;
    border-radius: 24px;
    transition: $transition;

    &:hover {
        background: $shadow;
    }
}

.eventImage {
    width: 170px;
    height: 170px;
    border-radius: 16px;
    object-fit: cover;
    object-position: center;
}

.eventData {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
}

.eventDataHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.eventDescription {
    line-height: 160%;
    color: $dark-purpure;
}

.eventDataFooter {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    padding-top: 8px;
    border-top: 1px solid $purpure;
}

.eventDataFooterItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    line-height: 160%;
}

.eventDataFooterItemHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $light-purpure;
    font-size: 12px;
    line-height: 140%;
}

.eventDataFooterItemHeaderIcon path {
    stroke: $dark-purpure;
}

@include screen-sm {
    .event {
        flex-direction: column;
        gap: 0;
        position: relative;
        padding: 0;
    }

    .eventImage {
        width: 100%;
        height: auto;
        border-radius: 24px 24px 0 0;
    }

    .eventData {
        padding: 16px;
    }

    .eventDataFooter {
        flex-wrap: wrap;
        gap: 16px 8px;
    }

    .eventDataFooterItem {
        width: calc(50% - 8px / 2);
    }

    .eventTags {
        position: absolute;
        top: 16px;
        left: 16px;
        right: 16px;
    }
}
