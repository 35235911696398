@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.blockContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.resetButton {
    padding: 17px 40px;
}

.specialFilter {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 160%;
}

.specialFilterIcon path {
    stroke: $dark-purpure;
}

.specialFilterSwitch {
    margin-left: auto;
}

.filterInput {
    width: 100%;
}

.showMoreBtn {
    align-self: flex-start;
}

.filterItems {
    max-height: 350px;
    overflow: auto;
}

@include screen-lg {
    .resetButton {
        display: none;
    }

    .filterItems {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
