@import 'assets/css/config';

.container {
    background-size: 100% 100% !important;
}

.content {
    display: flex;
    gap: 56px;
    position: relative;
    margin: 0 auto;
    padding: 60px 120px;
    max-width: calc(1200px + 120px * 2);
    background: rgba(36, 33, 50, 0.7);
    backdrop-filter: blur(12px);
}

.navbar {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    padding: 16px 0;
    top: 0;
    font-size: 14px;
    line-height: 160%;
}

.navbarIcon {
    width: 12px;
    height: 12px;

    path: {
        stroke: $dark-purpure;
    }
}

.navbarLink {
    font-size: 14px;

    &.active {
        opacity: 0.5;
    }
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.leftTop {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 0;
}

.leftBottom {
    display: flex;
    gap: 30px;
    padding: 40px 0 24px 0;
    border-top: 1px solid $purpure;
}

.leftBottomItem {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 12px;
    border: 1px solid $purpure;
    border-radius: 12px;
    font-size: 20px;
    line-height: 160%;
}

.leftBottomItemHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 160%;
    color: $dark-purpure;
}

.right {
    align-self: flex-start;
    flex-shrink: 0;
    width: 38%;
    border-radius: 24px;
    overflow: hidden;
}

.rightTop {
    position: relative;
    padding-top: 100%;
    width: 100%;
    height: 0;
}

.rightBottom {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background: $shadow;
    color: $light-purpure;
}

.eventImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.eventTagsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.eventTag {
    font-size: 16px;
}

.eventTitleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.eventTitle {
    align-self: flex-start;
    background: $white-purpure;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.eventStatus {
    font-size: 16px;
}

.eventDescription {
    font-size: 20px;
    line-height: 160%;
}

.eventButtons {
    display: flex;
    align-items: center;
    gap: 32px;
}

.eventLongButton {
    padding: 17px 65px;
}

.eventRegistration {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.eventRegistrationTextContainer {
    display: flex;
    gap: 8px;
}

.eventRegistrationText {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 16px;
    line-height: 140%;
    color: $light-purpure;
}

.eventRegistrationGreen {
    color: $detail-green;

    path {
        stroke: $detail-green;
    }
}

.eventOrganizersContainer {
    max-width: 450px;
}

.eventOrganizers {
    align-items: center;
    gap: 16px;
}

.eventOrganizerImageContainer {
    align-self: center;
    max-width: 100%;
}

.eventOrganizerImage {
    max-width: 100%;
    max-height: 60px;
    border-radius: 8px;
}

@include screen-nlg {
    .eventTagsContainer .eventStatus {
        display: none;
    }
}

@include screen-lg {
    .content {
        flex-direction: column;
        gap: 32px;
        padding: 44px 20px 0 20px;
    }

    .navbar {
        display: none;
    }

    .leftTop {
        padding: 0 0 32px 0;
    }

    .leftBottom {
        gap: 16px;
        padding: 32px 0 0 0;
    }

    .leftBottomItem {
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
    }

    .leftBottomItemHeader {
        font-size: 14px;
    }

    .right {
        width: 100%;
    }

    .rightTop {
        display: none;
    }

    .rightBottom {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .rightBottomTitle {
        font-size: 16px;
    }

    .eventStatus {
        font-size: 14px;

        .eventTitleContainer & {
            display: none;
        }
    }

    .eventTag {
        font-size: 14px;
    }

    .eventDescription {
        font-size: 16px;
    }

    .eventLongButton {
        padding: 17px 30px;
    }

    .eventOrganizers {
        gap: 32px;
    }
}

@media (min-width: 577px) {
    .moreTextMobile {
        display: none;
    }
}

@media (max-width: 576px) {
    .moreTextDesktop {
        display: none;
    }

    .leftBottom {
        flex-direction: column;
        gap: 8px;
    }

    .leftBottomItem {
        flex-direction: row;
    }

    .leftBottomItemHeader {
        font-size: 12px;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .rightBottom {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 16px;
    }

    .eventTitleContainer {
        flex-direction: column-reverse;
        margin-bottom: calc(8px - 24px);
    }

    .eventTitle {
        font-size: 30px;
    }

    .eventStatus {
        .eventTagsContainer & {
            display: none;
        }

        .eventTitleContainer & {
            display: flex;
        }
    }
}
