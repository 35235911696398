@font-face {
    font-family: 'Mont Light';
    src: local('Mont ExtraLight'), local('Mont-ExtraLight'),
        url('Mont-ExtraLight.woff2') format('woff2'),
        url('Mont-ExtraLight.woff') format('woff'),
        url('Mont-ExtraLight.ttf') format('truetype'),
        url('Mont-ExtraLight.eot') format('embedded-opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Regular';
    src: local('Mont Regular'), local('Mont-Regular'),
        url('Mont-Regular.woff2') format('woff2'),
        url('Mont-Regular.woff') format('woff'),
        url('Mont-Regular.ttf') format('truetype'),
        url('Mont-Regular.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont SemiBold';
    src: local('Mont SemiBold'), local('Mont-SemiBold'),
        url('Mont-SemiBold.woff2') format('woff2'),
        url('Mont-SemiBold.woff') format('woff'),
        url('Mont-SemiBold.ttf') format('truetype'),
        url('Mont-SemiBold.eot') format('embedded-opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
