@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    line-height: 160%;
}

.item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: $white;
    border: 1px solid $purpure;
    border-radius: 4px;
    transition: $transition;

    path {
        stroke: $white;
    }

    .item.active & {
        background: $purpure;
    }

    .item:hover & {
        background: $purpure;
        border: 1px solid $white;
    }
}

.info {
    margin-left: auto;
    padding-left: 4px;
    color: $dark-purpure;
    font-size: 12px;
    line-height: 140%;
}
