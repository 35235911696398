@import 'assets/css/config';

.container {
    position: relative;
    width: 34px;
    height: 17px;
    border: 1px solid $purpure;
    border-radius: 12px;
    background: $white;
    cursor: pointer;
    transition: $transition;

    &.active {
        background: $purpure;
    }

    &:hover {
       border-color: $white;
    }
}

.switcher {
    position: absolute;
    left: 10.42%;
    right: 56.25%;
    top: 16.67%;
    bottom: 16.67%;
    border-radius: 50%;
    background: $purpure;
    transition: $transition;

    .container.active & {
        right: 10.42%;
        left: 56.25%;
        background: $white;
    }
}
