@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px;
    width: 100%;
    max-width: 1240px;
}

.title {
    align-self: flex-start;
    background: $white-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.challenges {
    display: flex;
    gap: 12px;
}

.challenge {
    padding: 4px 15px;
    font-size: 14px;
    line-height: 160%;
    white-space: nowrap;

    &.active:after,
    &.active:before {
        background: $purpure;
    }
}

.places {
    display: flex;
    justify-content: space-around;
}

.place {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.placeInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.placeTitle {
    color: $purpure;
}

.pageBtn {
    display: block;
    margin: 0 auto;
    padding: 12px 30px;
}

@include screen-md {
    .places {
        justify-content: space-between;
    }
}

@include screen-xs {
    .challenges {
        padding: 0 20px;
        margin: 0 -20px;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .places {
        flex-direction: column;
        gap: 32px;
    }
}
