@import 'assets/css/config';

.button {
    position: relative;
    padding: 12px 30px;
    z-index: 1;
    border-radius: 40px;
    color: $white;
    border: none;
    background: none;
    font-size: 16px;
    font-family: $light;
    line-height: 140%;
    transition: $transition;
    outline: none;

    &.text {
        padding: 0;
        color: $purpure;
    }

    &.primary {
        background: $blue-purpure;
    }

    &.secondary {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: $white-purpure;
            border-radius: 40px;
            z-index: -1;
        }

        &:after {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            left: 1px;
            bottom: 1px;
            background: $black;
            border-radius: 40px;
            z-index: -1;
        }
    }

    &[disabled] {
        opacity: 0.5;
    }

    &:not([disabled]):hover {
        cursor: pointer;

        &.text {
            color: $white !important;
        }

        &.primary,
        &.secondary {
            box-shadow: 0px 10px 16px rgba(63, 59, 110, 0.5);

            &:after,
            &:before {
                background: $blue-purpure-hover !important;
            }
        }
    }

    &:not([disabled]):active {
        font-size: 15px;
    }
}
