@import 'assets/css/config';

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 44px;
    background: linear-gradient(
            314.35deg,
            rgba(110, 83, 173, 0.8) 2.98%,
            rgba(109, 101, 255, 0.8) 97%,
            rgba(7, 0, 135, 0.8) 97%
        ),
        url(../../assets/images/intro.png);
    background-size: 100% 200%;
    background-position: 0 -80px;
}

.introTitle {
    font-size: 64px;
}

.introSearch {
    width: 581px;
}

.content {
    display: flex;
    gap: 32px;
    margin: 0 auto;
    padding: 50px 16px;
    max-width: 1232px;
}

.sidebar {
    flex-shrink: 0;
    padding-top: 47px;
    width: 23%;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
}

.mainHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainHeaderActions {
    display: flex;
    gap: 24px;
}

.mainHeaderActionsBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $dark-purpure;

    path {
        stroke: $dark-purpure;
    }
}

.mainHeaderActionsBtnActiveIcon {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $light-purpure;
    }

    path {
        fill: $dark-purpure;
    }
}

.sortingItems {
    display: flex;
    gap: 16px;
}

.sortingItem {
    color: $white !important;
    font-size: 14px;

    &.active,
    &:hover {
        color: $purpure !important;
    }
}

.filtersActions {
    display: flex;
    gap: 32px;
    margin-top: 8px;
}

@include screen-nlg {
    .mainHeaderActions.mobile,
    .filtersActions {
        display: none;
    }
}

@include screen-lg {
    .mainHeaderActions {
        gap: 30px;

        &.desktop {
            display: none;
        }
    }

    .introTitle {
        font-size: 40px;
    }

    .introDescription {
        font-size: 18px;
    }

    .content {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .sidebar {
        display: none;
    }

    .sortingItems {
        flex-direction: column;
        align-items: flex-start;
        align-self: flex-start;
        gap: 24px;
    }

    .sortingItem {
        font-size: 16px;
    }
}

.loading {
    margin: 0 auto;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@include screen-nsm {
    .sortingXMobileBtn,
    .filtersXMobileAction {
        display: none;
    }
}

@include screen-sm {
    .sortingMobileBtn,
    .filtersMobileAction {
        display: none;
    }

    .introTitle {
        font-size: 30px;
    }

    .introDescription {
        font-size: 16px;
        text-align: center;
    }

    .introSearch {
        font-size: 14px;
    }

    .mainHeader {
        flex-direction: column;
        gap: 16px;
    }
}
