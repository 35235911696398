@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    line-height: 160%;
}

.headerTitle {
    margin-right: auto;
}

.headerIcon {
    display: flex;
    cursor: pointer;

    path {
        stroke: $dark-purpure;
    }
}

.content {
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    transition: $transition;

    &.active {
        opacity: 1;
        max-height: 450px;
    }
}
