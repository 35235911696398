@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px;
    width: 100%;
    max-width: 1240px;
}

.title {
    align-self: flex-start;
    background: $white-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.items {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.itemsGroup {
    display: flex;
    justify-content: space-around;
}

.itemsGraphs {
    position: relative;
    margin-bottom: 5px;

    &:after {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        height: 1px;
        left: 0;
        right: 20%;
        z-index: -1;
        background: $blue;
    }
}

.item {
    width: 100%;
    color: $dark-purpure;

    &.active {
        color: $white;
    }
}

.itemGraph {
    display: block;
    margin-right: auto;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $blue;

    &.active {
        background: $white-purpure;
    }
}

.itemText {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.itemTitle {
    font-size: 16px;
    line-height: 160%;
}

@include screen-lg {
    .items {
        flex-direction: row;
        gap: 24px;
    }

    .itemsGroup {
        flex-direction: column;
    }

    .itemsGraphs {
        margin-bottom: 0;

        &:after {
            left: calc(50% - 1px);
            width: 1px;
            top: 0;
            height: 100%;
        }
    }

    .item:not(:last-child) {
        height: 50px;
    }

    .itemGraph {
        margin-bottom: auto;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 577px) {
    .itemMobileTitle {
        display: none;
    }
}

@media (max-width: 576px) {
    .item {
        height: 76px !important;
    }

    .itemDesktopTitles {
        display: none;
    }

    .itemGraph:last-child {
        margin-bottom: auto;
    }

    .itemsGraphs:after {
        height: calc(100% - 76px);
    }
}
