$black: #242132;
$shadow: #3f3b6e;
$dark-purpure: #837fb8;
$blue: #746cff;
$purpure: #ae8cfc;
$light-purpure: #dcd0ff;
$white: #fefcff;

$blue-purpure: linear-gradient(314.35deg, #ae8cfc 2.98%, #746cff 97%);
$blue-purpure-hover: linear-gradient(314.35deg, #b394fb 2.98%, #6d65ff 97%);
$white-purpure: linear-gradient(270deg, #ae8cfc 1.04%, #fefcff 100%);

$detail-red: #e92362;
$detail-pink: #d96b91;
$detail-orange: #e59a83;
$detail-green: #6de1b4;
$detail-light-blue: #87e2ff;

$transition: 0.3s;

$regular: 'Mont Regular';
$light: 'Mont Light';
$semibold: 'Mont SemiBold';

@mixin screen-xs {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin screen-sm {
    @media (max-width: 640px) {
        @content;
    }
}

@mixin screen-nsm {
    @media (min-width: 641px) {
        @content;
    }
}

@mixin screen-md {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin screen-nmd {
    @media (min-width: 769px) {
        @content;
    }
}

@mixin screen-lg {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin screen-nlg {
    @media (min-width: 1025px) {
        @content;
    }
}