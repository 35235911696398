@import 'assets/css/config';

.container {
    display: flex;
    align-items: center;
    gap: 24px;

    &.noMobile {
        .items {
            gap: 0 !important;
        }
    }
}

.button {
    flex-shrink: 0;
    padding: 6px;
    border-radius: 12px;

    svg {
        width: 36px;
        height: 36px;
    }

    path {
        stroke: $white;
    }
}

.content {
    width: 100%;
    overflow: hidden;
}

.items {
    display: flex;
    transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.itemsVisible {
    display: flex;
    justify-content: space-around;
    flex-shrink: 0;
    width: 100%;
}

@include screen-lg {
    .container:not(.noMobile) {
        margin: 0 -20px;

        .itemsVisible {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }

        .button {
            display: none;
        }

        .content {
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .itemsVisible {
            width: auto;
        }
    }
}

@include screen-md {
    .container.noMobile {
        .button svg {
            width: 32px;
            height: 32px;
        }
    }
}

@include screen-sm {
    .container.noMobile {
        .button svg {
            width: 24px;
            height: 24px;
        }
    }
}
