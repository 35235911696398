@import 'assets/css/config';

.container {
    position: sticky;
    top: 88px;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    z-index: 2;
    background: $black;
    border-bottom: 1px solid $shadow;
    transition: $transition;

    &.active {
        opacity: 1;
        max-height: 1000px;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 12px 16px;
    max-width: 1232px;
}

.eventName {
    background: $white-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.eventDates {
    font-size: 16px;
    line-height: 160%;
}

.desktopButton {
    padding: 17px 65.5px;
}

@media (min-width: 577px) {
    .mobileButton {
        display: none;
    }
}

@media (max-width: 576px) {
    .eventName {
        font-size: 14px;
    }

    .eventDates {
        font-size: 12px;
    }

    .desktopButton {
        display: none;
    }
}
