#allrecords {
    /* background: #0a0a0a; */
    background: #101011;
}


.footer-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  margin-left: 50px;
}

.footer-link {
  display: flex;
align-items: center;
gap: 12px;
padding: 15px 20px;
border: 1px solid #8F00FF;
border-radius: 54px;
color: #FFF !important;
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
white-space: nowrap;
justify-content: center;
}

@media screen and (max-width: 960px){
.t977__col_center {
width: calc(100% - 360px) !important;
}

.footer-links {
  margin-left: 20px;
  /* margin-bottom: 0; */
}
}

@media screen and (max-width: 640px) {
.t977__col_center {
  width: 100% !important;
}

.footer-links {
width: min-content;
margin-left: 0;
}
}

.prizes {
  z-index: 3;
  position: relative;
}

.prizes,
.prizes * {
  box-sizing: border-box;
}

.prizes p {
  margin: 0;
}

.prizes__container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 100px 20px;
  width: 100%;
  max-width: calc(1200px);
  margin: 0 auto;
}

.prizes__title {
  color: #ffffff;
  font-size: 40px;
  font-family: "Arial", Arial, sans-serif;
  line-height: 1.55;
  font-weight: 600;
  letter-spacing: 5px;
}

.prizes__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.prizes__list {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.prizes__prize {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 10px;
  border: 5px solid #8f00ff;
  padding: 35px 15px;
  width: 100%;
  height: 550px;
  background: #101011;
} 

.prizes__prize:nth-child(2) {
  height: 390px;
}

.prizes__prize:nth-child(3) {
  height: 230px;
}

.prizes__prize-top {
  display: flex;
  align-items: baseline;
  gap: 15px;
  margin-bottom: -10px;
}

.prizes__prize-place-number {
  color: #ffffff;
  font-size: 93px;
  font-family: "Spacemono", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 600;
}

.prizes__prize-place-label {
  color: #ffffff;
  font-size: 38px;
  font-family: "Arial", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 400;
}

.prizes__prize-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.prizes__prize-amount-number {
  color: #ffffff;
  font-size: 58px;
  font-family: "Spacemono", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 600;
}

.prizes__prize-amount-label {
  color: #ffffff;
  font-size: 30px;
  font-family: "Arial", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 400;
}

.prizes__card {
  padding: 45px 30px;
  position: relative;
  border-radius: 10px;
  background: #dcd4e3;
}

.prizes__card-text {
  color: #000000;
  font-size: 38px;
  font-family: "Arial", Arial, sans-serif;
  line-height: 1.3;
  font-weight: 400;
  width: 60%;
}

.prizes__card-images {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: calc(40% - 20px);
}

.prizes__card-img {
  position: absolute;
  width: 75%;
}

.prizes__card-img:first-child {
  left: 0;
  bottom: 0;
  transform: rotate(23deg);
}

.prizes__card-img:last-child {
  right: 0;
  bottom: 15%;
  transform: rotate(52deg);
}

@media screen and (max-width: 1200px) {
  .prizes__container {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 960px;
  }

  .prizes__prize-place-number {
    font-size: 70px;
  }

  .prizes__prize-place-label {
    font-size: 28px;
  }

  .prizes__prize-amount-number {
font-size: 40px;
  }

  .prizes__prize-amount-label {
font-size: 20px;
  }
}

@media screen and (max-width: 960px) {
  .prizes__container {
    max-width: 640px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .prizes__list {
    flex-direction: column;
  }

  .prizes__prize {
    height: 200px !important;
  }

  .prizes__prize-bottom {
    justify-content: flex-start;
    gap: 30px;
  }

  .prizes__card-text {
    font-size: 26px;
  }
}

@media screen and (max-width: 639px) {
  .prizes__title {
    font-size: 30px;
  }

  .prizes__card-text {
    font-size: 20px;
  }

  .prizes__card-img {
    width: 90%;
  }
}


.footerlinks {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footerlinks > a {
  font-family: Arial;
  text-decoration: underline !important;
  font-size: 16px;
  color: #fff !important;
}

.footerlinks > a:last-child {
  padding-right: 100px;
}


@media screen and (max-width: 1200px) {

.footerlinks > a {
font-size: 14px;
}
}

@media screen and (max-width: 640px) {
  .footerlinks {
    margin-top: 30px;
    justify-content: flex-start;
  }

.footerlinks > a {
font-size: 12px;
}

  .footerlinks > a:first-child {
    padding-left: 20px;
  }

  .footerlinks > a:last-child {
    padding-right: 0;
  }
}


@media screen and (max-width: 400px) {
  .footerlinks {
    flex-direction: column;
    gap: 18px;
  }

  .footerlinks > a:last-child {
    padding-left: 20px;
  }
}
