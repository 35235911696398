
                             @media screen and (max-width: 980px) {
                            #rec677196653 .t456__leftcontainer {
                                padding: 20px;
                            }
                        }
                        @media screen and (max-width: 980px) {
                            #rec677196653 .t456__imglogo {
                                padding: 20px 0;
                            }
                        }


                        #rec677196653 .t-menu__link-item {
                            -webkit-transition: color 0.3s ease-in-out,
                                opacity 0.3s ease-in-out;
                            transition: color 0.3s ease-in-out,
                                opacity 0.3s ease-in-out;
                        }
                        #rec677196653
                            .t-menu__link-item:not(.t-active):not(.tooltipstered):hover {
                            color: #8f00ff !important;
                        }
                        #rec677196653
                            .t-menu__link-item:not(.t-active):not(.tooltipstered):focus-visible {
                            color: #8f00ff !important;
                        }
                        @supports (overflow: -webkit-marquee) and
                            (justify-content: inherit) {
                            #rec677196653 .t-menu__link-item,
                            #rec677196653 .t-menu__link-item.t-active {
                                opacity: 1 !important;
                            }
                        }

                        #rec677196761 .t794__tooltip-menu,
                        a[data-tooltip-menu-id='677196761']
                            + .t794__tooltip-menu {
                            background-color: #101110;
                            text-align: left;
                            max-width: 300px;
                            border-radius: 3px;
                            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0);
                        }
                        #rec677196761 .t794__tooltip-menu-corner-border_top {
                            border-top-color: #101110;
                        }
                        #rec677196761 .t794__tooltip-menu-corner-border_bottom {
                            border-bottom-color: #101110;
                        }
                        #rec677196761 .t794__tooltip-menu-corner_top {
                            border-top-color: #eee;
                        }
                        #rec677196761 .t794__tooltip-menu-corner_bottom {
                            border-bottom-color: #eee;
                        }
                        @media screen and (max-width: 980px) {
                            a[data-tooltip-menu-id='677196761']
                                + .t794__tooltip-menu {
                                max-width: 100%;
                            }
                        }
                        #rec677196761 .t794__typo.t-active,
                        a[data-tooltip-menu-id='677196761']
                            + .t794__tooltip-menu
                            .t794__typo.t-active {
                            color: #ffffff !important;
                        }
                        #rec677196761 .t794__typo:not(.t-active):hover {
                            color: #8f00ff !important;
                        }


                        #rec686346664 .t794__tooltip-menu,
                        a[data-tooltip-menu-id='686346664']
                            + .t794__tooltip-menu {
                            background-color: #101110;
                            text-align: left;
                            max-width: 300px;
                            border-radius: 3px;
                            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0);
                        }
                        #rec686346664 .t794__tooltip-menu-corner-border_top {
                            border-top-color: #101110;
                        }
                        #rec686346664 .t794__tooltip-menu-corner-border_bottom {
                            border-bottom-color: #101110;
                        }
                        #rec686346664 .t794__tooltip-menu-corner_top {
                            border-top-color: #eee;
                        }
                        #rec686346664 .t794__tooltip-menu-corner_bottom {
                            border-bottom-color: #eee;
                        }
                        @media screen and (max-width: 980px) {
                            a[data-tooltip-menu-id='686346664']
                                + .t794__tooltip-menu {
                                max-width: 100%;
                            }
                        }
                        #rec686346664 .t794__typo.t-active,
                        a[data-tooltip-menu-id='686346664']
                            + .t794__tooltip-menu
                            .t794__typo.t-active {
                            color: #ffffff !important;
                        }
                        #rec686346664 .t794__typo:not(.t-active):hover {
                            color: #8f00ff !important;
                        }

                    #rec676592631 .t396__artboard {
                        height: 750px;
                        background-color: #101011;
                    }
                    #rec676592631 .t396__filter {
                        height: 1400px;
                    }
                    #rec676592631 .t396__carrier {
                        height: 1400px;
                        background-position: center center;
                        background-attachment: scroll;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .t396__artboard {
                            height: 750px;
                        }
                        #rec676592631 .t396__filter {
                            height: 1340px;
                        }
                        #rec676592631 .t396__carrier {
                            height: 1340px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .t396__artboard {
                            height: 750px;
                        }
                        #rec676592631 .t396__filter {
                            height: 1200px;
                        }
                        #rec676592631 .t396__carrier {
                            height: 1200px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .t396__artboard {
                            height: 700px;
                        }
                        #rec676592631 .t396__filter {
                            height: 1000px;
                        }
                        #rec676592631 .t396__carrier {
                            height: 1000px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .t396__artboard {
                            height: 650px;
                        }
                        #rec676592631 .t396__filter {
                            height: 1030px;
                        }
                        #rec676592631 .t396__carrier {
                            height: 1030px;
                            background-attachment: scroll;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1700416371269'] {
                        z-index: 3;
                        top: -920px;
                        left: calc(50% - 600px + -473px);
                        width: 3100px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700416371269']
                        .tn-atom {
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700416371269']
                        .tn-atom {
                        -webkit-transform: rotate(243deg);
                        -moz-transform: rotate(243deg);
                        transform: rotate(243deg);
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1700416371269'] {
                            top: -920px;
                            left: calc(50% - 480px + -633px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1700416371269'] {
                            top: -1070px;
                            left: calc(50% - 320px + -913px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1700416371269'] {
                            top: -1070px;
                            left: calc(50% - 240px + -913px);
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1700416371269'] {
                            top: -1010px;
                            left: calc(50% - 160px + -1034px);
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701190918127'] {
                        z-index: 4;
                        top: 944px;
                        left: calc(50% - 600px + 20px);
                        width: 568px;
                        height: 295px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701190918127']
                        .tn-atom {
                        border-radius: 20px;
                        background-image: linear-gradient(
                            0turn,
                            rgba(28, 27, 31, 1) 0%,
                            rgba(16, 16, 17, 1) 100%
                        );
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190918127'] {
                            top: 872px;
                            width: 451px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190918127'] {
                            top: 782px;
                            width: 293px;
                            height: 200px;
                            border-radius: 20px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701190918127']
                            .tn-atom {
                            border-radius: 20px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190918127'] {
                            top: 690px;
                            left: calc(50% - 240px + 21px);
                            width: 450px;
                            height: 75px;
                            border-radius: 10px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701190918127']
                            .tn-atom {
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190918127'] {
                            top: 630px;
                            left: calc(50% - 160px + 22px);
                            width: 278px;
                            height: 117px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701190920250'] {
                        z-index: 5;
                        top: 944px;
                        left: calc(50% - 600px + 608px);
                        width: 568px;
                        height: 295px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701190920250']
                        .tn-atom {
                        border-radius: 20px;
                        background-image: linear-gradient(
                            0turn,
                            rgba(28, 27, 31, 1) 0%,
                            rgba(16, 16, 17, 1) 100%
                        );
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190920250'] {
                            top: 872px;
                            left: calc(50% - 480px + 492px);
                            width: 450px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190920250'] {
                            top: 782px;
                            left: calc(50% - 320px + 330px);
                            width: 293px;
                            height: 200px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190920250'] {
                            top: 777px;
                            left: calc(50% - 240px + 21px);
                            width: 448px;
                            height: 108px;
                            border-radius: 10px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701190920250']
                            .tn-atom {
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701190920250'] {
                            top: 760px;
                            left: calc(50% - 160px + 22px);
                            width: 278px;
                            height: 139px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1700386902451'] {
                        color: #ffffff;
                        z-index: 6;
                        top: 293px;
                        left: calc(50% - 600px + 21px);
                        width: 536px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700386902451']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 87px;
                        font-family: 'Spacemono', Arial, sans-serif;
                        line-height: 1.15;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1700386902451'] {
                            top: 230px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700386902451']
                            .tn-atom {
                            font-size: 64px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1700386902451'] {
                            width: 285px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700386902451']
                            .tn-atom {
                            font-size: 48px;
                            line-height: 1;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1700386902451'] {
                            top: 234px;
                            left: calc(50% - 160px + 21px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700386902451']
                            .tn-atom {
                            font-size: 32px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701332657958'] {
                        color: #8f00ff;
                        z-index: 7;
                        top: 430px;
                        left: calc(50% - 600px + 244px);
                        width: 488px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701332657958']
                        .tn-atom {
                        color: #8f00ff;
                        font-size: 43px;
                        font-family: 'Spacemono', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701332657958'] {
                            top: 320px;
                            left: calc(50% - 320px + 201px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701332657958']
                            .tn-atom {
                            font-size: 40px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701332657958'] {
                            top: 290px;
                            left: calc(50% - 240px + 156px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701332657958']
                            .tn-atom {
                            font-size: 28px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701332657958'] {
                            top: 272px;
                            left: calc(50% - 160px + 21px);
                            width: 281px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701332657958']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1700415577857'] {
                        color: #ffffff;
                        z-index: 8;
                        top: 842px;
                        left: calc(50% - 600px + 21px);
                        width: 116px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700415577857']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 40px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        letter-spacing: 3px;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1700415577857'] {
                            top: 770px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1700415577857'] {
                            top: 680px;
                            left: calc(50% - 320px + 20px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1700415577857'] {
                            top: 633px;
                            left: calc(50% - 240px + 21px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700415577857']
                            .tn-atom {
                            font-size: 30px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1700415577857'] {
                            top: 583px;
                            left: calc(50% - 160px + 21px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700415577857']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1700389780684'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 9;
                        top: 617px;
                        left: calc(50% - 600px + 20px);
                        width: 323px;
                        height: 68px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700389780684']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 22px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        letter-spacing: 1px;
                        border-width: 0px;
                        border-radius: 100px;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389780684'] {
                            top: 487px;
                            left: calc(50% - 320px + 18px);
                            width: 410px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389780684'] {
                            top: 425px;
                            left: calc(50% - 240px + 21px);
                            width: 453px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389780684'] {
                            top: 389px;
                            left: calc(50% - 160px + 21px);
                            width: 284px;
                            height: 55px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700389780684']
                            .tn-atom {
                            font-size: 16px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701705636103'] {
                        color: #80818c;
                        z-index: 10;
                        top: 509px;
                        left: calc(50% - 600px + 21px);
                        width: 618px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701705636103']
                        .tn-atom {
                        color: #80818c;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705636103'] {
                            left: calc(50% - 480px + 21px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705636103'] {
                            top: 392px;
                            left: calc(50% - 320px + 20px);
                            width: 476px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705636103'] {
                            top: 343px;
                            left: calc(50% - 240px + 21px);
                            width: 401px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701705636103']
                            .tn-atom {
                            font-size: 22px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705636103'] {
                            top: 319px;
                            left: calc(50% - 160px + 21px);
                            width: 290px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701705636103']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701705968413'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 11;
                        top: 617px;
                        left: calc(50% - 600px + 360px);
                        width: 410px;
                        height: 68px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701705968413']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 22px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        letter-spacing: 1px;
                        border-width: 1px;
                        border-radius: 100px;
                        background-color: #8f00ff;
                        background-position: center center;
                        border-color: #8f00ff;
                        border-style: solid;
                        transition: background-color 0s ease-in-out,
                            color 0s ease-in-out, border-color 0s ease-in-out;
                    }
                    @media screen and (max-width: 1199px) {
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705968413'] {
                            top: 582px;
                            left: calc(50% - 320px + 18px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705968413'] {
                            top: 509px;
                            left: calc(50% - 240px + 21px);
                            width: 453px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701705968413'] {
                            top: 456px;
                            left: calc(50% - 160px + 21px);
                            width: 284px;
                            height: 55px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701705968413']
                            .tn-atom {
                            font-size: 16px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701706291029'] {
                        color: #80818c;
                        z-index: 12;
                        top: 251px;
                        left: calc(50% - 600px + 23px);
                        width: 618px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701706291029']
                        .tn-atom {
                        color: #80818c;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701706291029'] {
                            left: calc(50% - 480px + 21px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701706291029'] {
                            top: 189px;
                            left: calc(50% - 320px + 21px);
                            width: 520px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701706291029'] {
                            top: 192px;
                            left: calc(50% - 240px + 21px);
                            width: 459px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701706291029']
                            .tn-atom {
                            font-size: 22px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701706291029'] {
                            top: 192px;
                            left: calc(50% - 160px + 21px);
                            width: 299px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701706291029']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701708212011'] {
                        color: #8f00ff;
                        text-align: center;
                        z-index: 13;
                        top: 997px;
                        left: calc(50% - 600px + 161px);
                        width: 287px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701708212011']
                        .tn-atom {
                        color: #8f00ff;
                        font-size: 43px;
                        font-family: 'Spacemono', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708212011'] {
                            top: 925px;
                            left: calc(50% - 480px + 103px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708212011'] {
                            top: 803px;
                            left: calc(50% - 320px + 65px);
                            width: 202px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708212011']
                            .tn-atom {
                            font-size: 32px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708212011'] {
                            top: 711px;
                            left: calc(50% - 240px + 43px);
                            width: 152px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708212011']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708212011'] {
                            top: 662px;
                            left: calc(50% - 160px + 85px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708212011']
                            .tn-atom {
                            font-size: 20px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701708225969'] {
                        color: #8f00ff;
                        text-align: center;
                        z-index: 14;
                        top: 997px;
                        left: calc(50% - 600px + 780px);
                        width: 224px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701708225969']
                        .tn-atom {
                        color: #8f00ff;
                        font-size: 43px;
                        font-family: 'Spacemono', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708225969'] {
                            top: 925px;
                            left: calc(50% - 480px + 605px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708225969'] {
                            top: 803px;
                            left: calc(50% - 320px + 364px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708225969']
                            .tn-atom {
                            font-size: 32px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708225969'] {
                            top: 807px;
                            left: calc(50% - 240px + 43px);
                            width: 119px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708225969']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708225969'] {
                            top: 790px;
                            left: calc(50% - 160px + 102px);
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708225969']
                            .tn-atom {
                            font-size: 20px;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701708585744'] {
                        color: #80818c;
                        text-align: center;
                        z-index: 15;
                        top: 1068px;
                        left: calc(50% - 600px + 181px);
                        width: 247px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701708585744']
                        .tn-atom {
                        color: #80818c;
                        font-size: 32px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708585744'] {
                            top: 1016px;
                            left: calc(50% - 480px + 123px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708585744'] {
                            top: 862px;
                            left: calc(50% - 320px + 68px);
                            width: 196px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708585744']
                            .tn-atom {
                            font-size: 22px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708585744'] {
                            top: 715px;
                            left: calc(50% - 240px + 223px);
                            width: 150px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708585744']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708585744'] {
                            top: 697px;
                            left: calc(50% - 160px + 74px);
                            width: 175px;
                        }
                        #rec676592631 .tn-elem[data-elem-id='1701708585744'] {
                            text-align: center;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                        color: #80818c;
                        text-align: center;
                        z-index: 16;
                        top: 1068px;
                        left: calc(50% - 600px + 674px);
                        width: 437px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701708607144']
                        .tn-atom {
                        color: #80818c;
                        font-size: 32px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                            top: 992px;
                            left: calc(50% - 480px + 499px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                            top: 862px;
                            left: calc(50% - 320px + 346px);
                            width: 260px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708607144']
                            .tn-atom {
                            font-size: 22px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                            top: 818px;
                            left: calc(50% - 240px + 230px);
                            width: 228px;
                        }
                        #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                            text-align: left;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701708607144']
                            .tn-atom {
                            font-size: 16px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                            top: 827px;
                            left: calc(50% - 160px + 46px);
                            width: 231px;
                        }
                        #rec676592631 .tn-elem[data-elem-id='1701708607144'] {
                            text-align: center;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1700389706823'] {
                        z-index: 17;
                        top: 275px;
                        left: calc(50% - 600px + 814px);
                        width: 250px;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700389706823']
                        .tn-atom {
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1700389706823']
                        .tn-atom {
                        -webkit-transform: rotate(343deg);
                        -moz-transform: rotate(343deg);
                        transform: rotate(343deg);
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389706823'] {
                            left: calc(50% - 480px + 654px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389706823'] {
                            top: 189px;
                            left: calc(50% - 320px + 410px);
                            width: 146px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389706823'] {
                            top: 197px;
                            left: calc(50% - 240px + 321px);
                            width: 110px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1700389706823'] {
                            top: 184px;
                            left: calc(50% - 160px + 245px);
                            width: 61px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1700389706823']
                            .tn-atom {
                            opacity: 0;
                        }
                    }
                    #rec676592631 .tn-elem[data-elem-id='1701673770827'] {
                        z-index: 18;
                        top: 944px;
                        left: calc(50% - 600px + 20px);
                        width: 568px;
                        height: 295px;
                    }
                    @media (min-width: 1200px) {
                        #rec676592631
                            .tn-elem.t396__elem--anim-hidden[data-elem-id='1701673770827'] {
                            opacity: 0;
                        }
                    }
                    #rec676592631
                        .tn-elem[data-elem-id='1701673770827']
                        .tn-atom {
                        border-width: 1px;
                        border-radius: 20px;
                        background-position: center center;
                        border-color: #8f00ff;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec676592631 .tn-elem[data-elem-id='1701673770827'] {
                            width: 451px;
                        }
                        #rec676592631
                            .tn-elem[data-elem-id='1701673770827']
                            .tn-atom {
                            opacity: 0;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec676592631 .tn-elem[data-elem-id='1701673770827'] {
                            top: 672px;
                            left: calc(50% - 320px + -646px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                    }
                    @media screen and (max-width: 479px) {
                        #rec676592631 .tn-elem[data-elem-id='1701673770827'] {
                            top: 772px;
                        }
                    }


                    #rec684399771 .t1106__number {
                        border-color: #8f00ff;
                        background: #101011;
                    }
                    #rec684399771 .t1106__line {
                        background-color: #8f00ff;
                    }
                    #rec684399771 .t1106__line_mobile {
                        background-color: #8f00ff;
                    }
                    #rec684399771 .t1106__title {
                        font-size: 26px;
                        color: #ffffff;
                        font-family: 'Arial';
                    }
                    #rec684399771 .t1106__text {
                        font-size: 20px;
                        color: #80818c;
                        font-family: 'Arial';
                    }

                    #rec679616758 .t396__artboard {
                        height: 630px;
                        background-color: #101011;
                    }
                    #rec679616758 .t396__filter {
                        height: 630px;
                    }
                    #rec679616758 .t396__carrier {
                        height: 630px;
                        background-position: center center;
                        background-attachment: scroll;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec679616758 .t396__artboard {
                            height: 500px;
                        }
                        #rec679616758 .t396__filter {
                            height: 500px;
                        }
                        #rec679616758 .t396__carrier {
                            height: 500px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec679616758 .t396__artboard {
                            height: 780px;
                        }
                        #rec679616758 .t396__filter {
                            height: 780px;
                        }
                        #rec679616758 .t396__carrier {
                            height: 780px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec679616758 .t396__artboard {
                            height: 680px;
                        }
                        #rec679616758 .t396__filter {
                            height: 680px;
                        }
                        #rec679616758 .t396__carrier {
                            height: 680px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec679616758 .t396__artboard {
                            height: 720px;
                        }
                        #rec679616758 .t396__filter {
                            height: 720px;
                        }
                        #rec679616758 .t396__carrier {
                            height: 720px;
                            background-attachment: scroll;
                        }
                    }
                    #rec679616758 .tn-elem[data-elem-id='1701191038752'] {
                        z-index: 2;
                        top: 169px;
                        left: calc(50% - 600px + -100px);
                        width: 290px;
                        height: 134px;
                        -webkit-filter: blur(50px);
                        filter: blur(50px);
                        border-radius: 3000px;
                    }
                    #rec679616758
                        .tn-elem[data-elem-id='1701191038752']
                        .tn-atom {
                        border-radius: 3000px;
                        opacity: 0.45;
                        background-image: linear-gradient(
                            0.285turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        border-color: transparent;
                        border-style: solid;
                    }
                    #rec679616758
                        .tn-elem[data-elem-id='1701191038752']
                        .tn-atom {
                        -webkit-transform: rotate(22deg);
                        -moz-transform: rotate(22deg);
                        transform: rotate(22deg);
                    }
                    @media screen and (max-width: 1199px) {
                        #rec679616758 .tn-elem[data-elem-id='1701191038752'] {
                            top: 136px;
                            left: calc(50% - 480px + -94px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec679616758 .tn-elem[data-elem-id='1701191038752'] {
                            top: 75px;
                            left: calc(50% - 320px + 72px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec679616758 .tn-elem[data-elem-id='1701191038752'] {
                            top: 69px;
                            left: calc(50% - 240px + -87px);
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec679616758 .tn-elem[data-elem-id='1701191038752'] {
                            top: 69px;
                            left: calc(50% - 160px + -74px);
                        }
                    }
                    #rec679616758 .tn-elem[data-elem-id='1701174292948'] {
                        color: transparent;
                        background-image: linear-gradient(
                            0.285turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        -webkit-background-clip: text;
                        will-change: transform;
                        z-index: 3;
                        top: 111px;
                        left: calc(50% - 600px + 412px);
                        width: 687px;
                    }
                    #rec679616758
                        .tn-elem[data-elem-id='1701174292948']
                        .tn-atom {
                        color: transparent;
                        background-image: linear-gradient(
                            0.285turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        -webkit-background-clip: text;
                        will-change: transform;
                        font-size: 44px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        letter-spacing: 1px;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec679616758 .tn-elem[data-elem-id='1701174292948'] {
                            top: 121px;
                            left: calc(50% - 480px + 331px);
                            width: 568px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701174292948']
                            .tn-atom {
                            font-size: 34px;
                            line-height: 1.2;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec679616758 .tn-elem[data-elem-id='1701174292948'] {
                            top: 364px;
                            left: calc(50% - 320px + 28px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec679616758 .tn-elem[data-elem-id='1701174292948'] {
                            top: 335px;
                            left: calc(50% - 240px + 26px);
                            width: 461px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701174292948']
                            .tn-atom {
                            font-size: 30px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec679616758 .tn-elem[data-elem-id='1701174292948'] {
                            top: 291px;
                            left: calc(50% - 160px + 23px);
                            width: 301px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701174292948']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    #rec679616758 .tn-elem[data-elem-id='1701179358211'] {
                        color: #ffffff;
                        z-index: 4;
                        top: 201px;
                        left: calc(50% - 600px + 412px);
                        width: 769px;
                    }
                    #rec679616758
                        .tn-elem[data-elem-id='1701179358211']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 40px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 600;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec679616758 .tn-elem[data-elem-id='1701179358211'] {
                            top: 178px;
                            left: calc(50% - 480px + 331px);
                            width: 620px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701179358211']
                            .tn-atom {
                            font-size: 33px;
                            line-height: 1.2;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec679616758 .tn-elem[data-elem-id='1701179358211'] {
                            top: 421px;
                            left: calc(50% - 320px + 28px);
                            width: 588px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701179358211']
                            .tn-atom {
                            line-height: 1.3;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec679616758 .tn-elem[data-elem-id='1701179358211'] {
                            top: 379px;
                            left: calc(50% - 240px + 26px);
                            width: 459px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701179358211']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec679616758 .tn-elem[data-elem-id='1701179358211'] {
                            top: 328px;
                            left: calc(50% - 160px + 23px);
                            width: 289px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701179358211']
                            .tn-atom {
                            font-size: 22px;
                        }
                    }
                    #rec679616758 .tn-elem[data-elem-id='1701185018302'] {
                        color: #ffffff;
                        z-index: 5;
                        top: 201px;
                        left: calc(50% - 600px + 20px);
                        width: 370px;
                    }
                    #rec679616758
                        .tn-elem[data-elem-id='1701185018302']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 20px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec679616758 .tn-elem[data-elem-id='1701185018302'] {
                            top: 178px;
                            left: calc(50% - 480px + 10px);
                            width: 375px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec679616758 .tn-elem[data-elem-id='1701185018302'] {
                            top: 125px;
                            left: calc(50% - 320px + 230px);
                            width: 368px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec679616758 .tn-elem[data-elem-id='1701185018302'] {
                            top: 151px;
                            left: calc(50% - 240px + 26px);
                            width: 338px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701185018302']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec679616758 .tn-elem[data-elem-id='1701185018302'] {
                            top: 111px;
                            left: calc(50% - 160px + 23px);
                            width: 299px;
                        }
                    }
                    #rec679616758 .tn-elem[data-elem-id='1701170774125'] {
                        color: #80818c;
                        z-index: 6;
                        top: 441px;
                        left: calc(50% - 600px + 20px);
                        width: 395px;
                    }
                    #rec679616758
                        .tn-elem[data-elem-id='1701170774125']
                        .tn-atom {
                        color: #80818c;
                        font-size: 20px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec679616758 .tn-elem[data-elem-id='1701170774125'] {
                            top: 334px;
                            left: calc(50% - 480px + 10px);
                            width: 300px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec679616758 .tn-elem[data-elem-id='1701170774125'] {
                            top: 180px;
                            left: calc(50% - 320px + 230px);
                            width: 403px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec679616758 .tn-elem[data-elem-id='1701170774125'] {
                            top: 151px;
                            left: calc(50% - 240px + 128px);
                            width: 341px;
                        }
                        #rec679616758
                            .tn-elem[data-elem-id='1701170774125']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec679616758 .tn-elem[data-elem-id='1701170774125'] {
                            top: 147px;
                            left: calc(50% - 160px + 23px);
                            width: 291px;
                        }
                    }

                    #rec679668758 .t120__title {
                        color: #ffffff;
                        font-weight: 600;
                        font-family: 'Arial';
                    }
                    @media screen and (min-width: 900px) {
                        #rec679668758 .t120__title {
                            font-size: 40px;
                        }
                    }
                    #rec679668758 .t120__descr {
                        color: #80818c;
                    }

                    #rec684396364 .t-slds__bullet_active .t-slds__bullet_body {
                        background-color: #222 !important;
                    }
                    #rec684396364 .t-slds__bullet:hover .t-slds__bullet_body {
                        background-color: #222 !important;
                    }
                    #rec684396364 .t776__content .t776__title {
                        color: #ffffff;
                        font-weight: 700;
                        font-family: 'Arial';
                    }
                    #rec684396364 .t776__price {
                        font-weight: 400;
                    }
                    #rec684396364 .t776__price_old {
                        font-weight: 400;
                    }
                    #rec684396364 .t776__textwrapper .t776__descr {
                        color: #80818c;
                        font-family: 'Arial';
                    }
                    #rec684396364 .t-popup .t776__title {
                        color: #ffffff;
                        font-weight: 700;
                        font-family: 'Arial';
                    }
                    #rec684396364 .t-popup .t776__descr {
                        color: #80818c;
                        font-family: 'Arial';
                    }

                    #rec678565843 .t396__artboard {
                        height: 580px;
                        background-color: #101011;
                    }
                    #rec678565843 .t396__filter {
                        height: 580px;
                    }
                    #rec678565843 .t396__carrier {
                        height: 580px;
                        background-position: center center;
                        background-attachment: scroll;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .t396__artboard {
                            height: 550px;
                        }
                        #rec678565843 .t396__filter {
                            height: 550px;
                        }
                        #rec678565843 .t396__carrier {
                            height: 550px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .t396__artboard {
                            height: 700px;
                        }
                        #rec678565843 .t396__filter {
                            height: 700px;
                        }
                        #rec678565843 .t396__carrier {
                            height: 700px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .t396__artboard {
                            height: 550px;
                        }
                        #rec678565843 .t396__filter {
                            height: 550px;
                        }
                        #rec678565843 .t396__carrier {
                            height: 550px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .t396__artboard {
                            height: 850px;
                        }
                        #rec678565843 .t396__filter {
                            height: 850px;
                        }
                        #rec678565843 .t396__carrier {
                            height: 850px;
                            background-attachment: scroll;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1701191171134'] {
                        z-index: 2;
                        top: 680px;
                        left: calc(50% - 600px + 850px);
                        width: 184px;
                        height: 104px;
                        -webkit-filter: blur(50px);
                        filter: blur(50px);
                        border-radius: 3000px;
                    }
                    @media (min-width: 1200px) {
                        #rec678565843
                            .tn-elem.t396__elem--anim-hidden[data-elem-id='1701191171134'] {
                            opacity: 0;
                        }
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701191171134']
                        .tn-atom {
                        border-radius: 3000px;
                        opacity: 0.35;
                        background-image: linear-gradient(
                            0.285turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        border-color: transparent;
                        border-style: solid;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701191171134']
                        .tn-atom {
                        -webkit-transform: rotate(336deg);
                        -moz-transform: rotate(336deg);
                        transform: rotate(336deg);
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1701191171134'] {
                            top: 268px;
                            left: calc(50% - 480px + 661px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1701191171134'] {
                            top: 473px;
                            left: calc(50% - 320px + 308px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1701191171134'] {
                            top: 304px;
                            left: calc(50% - 240px + 336px);
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1701191171134'] {
                            top: 600px;
                            left: calc(50% - 160px + 20px);
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1701084664580'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 3;
                        top: 83px;
                        left: calc(50% - 600px + 513px);
                        width: 280px;
                        height: 120px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701084664580']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        border-radius: 20px;
                        background-color: #242227;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701084664580']
                        .tn-atom::before,
                    #rec678565843
                        .tn-elem[data-elem-id='1701084664580']
                        .tn-atom::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        box-sizing: content-box;
                        pointer-events: none;
                        border-radius: 20px;
                        transition: opacity 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701084664580']
                        .tn-atom::before {
                        z-index: -2;
                        opacity: 1;
                        background-color: #242227;
                        background-image: none;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701084664580']
                        .tn-atom::after {
                        z-index: -1;
                        opacity: 0;
                        background-color: transparent;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 2%,
                            rgba(200, 48, 255, 1) 100%
                        );
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1701084664580']
                        .tn-atom {
                        position: relative;
                        z-index: 1;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                    }
                    @media (hover), (min-width: 0\0) {
                        #rec678565843
                            .tn-elem[data-elem-id='1701084664580']
                            .tn-atom:hover::after {
                            opacity: 1;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1701084664580'] {
                            top: 88px;
                            left: calc(50% - 480px + 407px);
                            width: 260px;
                            height: 100px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1701084664580'] {
                            top: 311px;
                            left: calc(50% - 320px + 12px);
                            width: 250px;
                            height: 80px;
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1701084664580']
                            .tn-atom::before,
                        #rec678565843
                            .tn-elem[data-elem-id='1701084664580']
                            .tn-atom::after {
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1701084664580']
                            .tn-atom {
                            font-size: 22px;
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1701084664580'] {
                            top: 254px;
                            left: calc(50% - 240px + 12px);
                            width: 206px;
                            height: 60px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1701084664580']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1701084664580'] {
                            top: 315px;
                            left: calc(50% - 160px + 12px);
                            width: 298px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702030961492'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 4;
                        top: 83px;
                        left: calc(50% - 600px + 813px);
                        width: 280px;
                        height: 120px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030961492']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        border-radius: 20px;
                        background-color: #242227;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030961492']
                        .tn-atom::before,
                    #rec678565843
                        .tn-elem[data-elem-id='1702030961492']
                        .tn-atom::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        box-sizing: content-box;
                        pointer-events: none;
                        border-radius: 20px;
                        transition: opacity 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030961492']
                        .tn-atom::before {
                        z-index: -2;
                        opacity: 1;
                        background-color: #242227;
                        background-image: none;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030961492']
                        .tn-atom::after {
                        z-index: -1;
                        opacity: 0;
                        background-color: transparent;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 2%,
                            rgba(200, 48, 255, 1) 100%
                        );
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030961492']
                        .tn-atom {
                        position: relative;
                        z-index: 1;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                    }
                    @media (hover), (min-width: 0\0) {
                        #rec678565843
                            .tn-elem[data-elem-id='1702030961492']
                            .tn-atom:hover::after {
                            opacity: 1;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030961492'] {
                            top: 88px;
                            left: calc(50% - 480px + 683px);
                            width: 260px;
                            height: 100px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030961492'] {
                            top: 311px;
                            left: calc(50% - 320px + 283px);
                            width: 250px;
                            height: 80px;
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030961492']
                            .tn-atom::before,
                        #rec678565843
                            .tn-elem[data-elem-id='1702030961492']
                            .tn-atom::after {
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030961492']
                            .tn-atom {
                            font-size: 22px;
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030961492'] {
                            top: 254px;
                            left: calc(50% - 240px + 234px);
                            width: 206px;
                            height: 60px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030961492']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030961492'] {
                            top: 387px;
                            left: calc(50% - 160px + 12px);
                            width: 298px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702030965721'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 5;
                        top: 223px;
                        left: calc(50% - 600px + 513px);
                        width: 280px;
                        height: 120px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030965721']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        border-radius: 20px;
                        background-color: #242227;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030965721']
                        .tn-atom::before,
                    #rec678565843
                        .tn-elem[data-elem-id='1702030965721']
                        .tn-atom::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        box-sizing: content-box;
                        pointer-events: none;
                        border-radius: 20px;
                        transition: opacity 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030965721']
                        .tn-atom::before {
                        z-index: -2;
                        opacity: 1;
                        background-color: #242227;
                        background-image: none;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030965721']
                        .tn-atom::after {
                        z-index: -1;
                        opacity: 0;
                        background-color: transparent;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 2%,
                            rgba(200, 48, 255, 1) 100%
                        );
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030965721']
                        .tn-atom {
                        position: relative;
                        z-index: 1;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                    }
                    @media (hover), (min-width: 0\0) {
                        #rec678565843
                            .tn-elem[data-elem-id='1702030965721']
                            .tn-atom:hover::after {
                            opacity: 1;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030965721'] {
                            top: 204px;
                            left: calc(50% - 480px + 407px);
                            width: 260px;
                            height: 100px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030965721'] {
                            top: 407px;
                            left: calc(50% - 320px + 12px);
                            width: 250px;
                            height: 80px;
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030965721']
                            .tn-atom::before,
                        #rec678565843
                            .tn-elem[data-elem-id='1702030965721']
                            .tn-atom::after {
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030965721']
                            .tn-atom {
                            font-size: 22px;
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030965721'] {
                            top: 331px;
                            left: calc(50% - 240px + 12px);
                            width: 206px;
                            height: 60px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030965721']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030965721'] {
                            top: 459px;
                            left: calc(50% - 160px + 12px);
                            width: 298px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702030968509'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 6;
                        top: 223px;
                        left: calc(50% - 600px + 813px);
                        width: 280px;
                        height: 120px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030968509']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        border-radius: 20px;
                        background-color: #242227;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030968509']
                        .tn-atom::before,
                    #rec678565843
                        .tn-elem[data-elem-id='1702030968509']
                        .tn-atom::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        box-sizing: content-box;
                        pointer-events: none;
                        border-radius: 20px;
                        transition: opacity 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030968509']
                        .tn-atom::before {
                        z-index: -2;
                        opacity: 1;
                        background-color: #242227;
                        background-image: none;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030968509']
                        .tn-atom::after {
                        z-index: -1;
                        opacity: 0;
                        background-color: transparent;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 2%,
                            rgba(200, 48, 255, 1) 100%
                        );
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030968509']
                        .tn-atom {
                        position: relative;
                        z-index: 1;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                    }
                    @media (hover), (min-width: 0\0) {
                        #rec678565843
                            .tn-elem[data-elem-id='1702030968509']
                            .tn-atom:hover::after {
                            opacity: 1;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030968509'] {
                            top: 204px;
                            left: calc(50% - 480px + 683px);
                            width: 260px;
                            height: 100px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030968509'] {
                            top: 407px;
                            left: calc(50% - 320px + 283px);
                            width: 250px;
                            height: 80px;
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030968509']
                            .tn-atom::before,
                        #rec678565843
                            .tn-elem[data-elem-id='1702030968509']
                            .tn-atom::after {
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030968509']
                            .tn-atom {
                            font-size: 22px;
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030968509'] {
                            top: 331px;
                            left: calc(50% - 240px + 234px);
                            width: 206px;
                            height: 60px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030968509']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030968509'] {
                            top: 531px;
                            left: calc(50% - 160px + 12px);
                            width: 298px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702030969689'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 7;
                        top: 363px;
                        left: calc(50% - 600px + 513px);
                        width: 280px;
                        height: 120px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030969689']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        border-radius: 20px;
                        background-color: #242227;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030969689']
                        .tn-atom::before,
                    #rec678565843
                        .tn-elem[data-elem-id='1702030969689']
                        .tn-atom::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        box-sizing: content-box;
                        pointer-events: none;
                        border-radius: 20px;
                        transition: opacity 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030969689']
                        .tn-atom::before {
                        z-index: -2;
                        opacity: 1;
                        background-color: #242227;
                        background-image: none;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030969689']
                        .tn-atom::after {
                        z-index: -1;
                        opacity: 0;
                        background-color: transparent;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 2%,
                            rgba(200, 48, 255, 1) 100%
                        );
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030969689']
                        .tn-atom {
                        position: relative;
                        z-index: 1;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                    }
                    @media (hover), (min-width: 0\0) {
                        #rec678565843
                            .tn-elem[data-elem-id='1702030969689']
                            .tn-atom:hover::after {
                            opacity: 1;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030969689'] {
                            top: 320px;
                            left: calc(50% - 480px + 407px);
                            width: 260px;
                            height: 100px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030969689'] {
                            top: 503px;
                            left: calc(50% - 320px + 12px);
                            width: 250px;
                            height: 80px;
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030969689']
                            .tn-atom::before,
                        #rec678565843
                            .tn-elem[data-elem-id='1702030969689']
                            .tn-atom::after {
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030969689']
                            .tn-atom {
                            font-size: 22px;
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030969689'] {
                            top: 408px;
                            left: calc(50% - 240px + 12px);
                            width: 206px;
                            height: 60px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030969689']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030969689'] {
                            top: 603px;
                            left: calc(50% - 160px + 12px);
                            width: 298px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702030970756'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 8;
                        top: 363px;
                        left: calc(50% - 600px + 813px);
                        width: 280px;
                        height: 120px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030970756']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 600;
                        border-radius: 20px;
                        background-color: #242227;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030970756']
                        .tn-atom::before,
                    #rec678565843
                        .tn-elem[data-elem-id='1702030970756']
                        .tn-atom::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        box-sizing: content-box;
                        pointer-events: none;
                        border-radius: 20px;
                        transition: opacity 0.2s ease-in-out;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030970756']
                        .tn-atom::before {
                        z-index: -2;
                        opacity: 1;
                        background-color: #242227;
                        background-image: none;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030970756']
                        .tn-atom::after {
                        z-index: -1;
                        opacity: 0;
                        background-color: transparent;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 2%,
                            rgba(200, 48, 255, 1) 100%
                        );
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702030970756']
                        .tn-atom {
                        position: relative;
                        z-index: 1;
                        background-color: transparent;
                        background-image: none;
                        border: none;
                    }
                    @media (hover), (min-width: 0\0) {
                        #rec678565843
                            .tn-elem[data-elem-id='1702030970756']
                            .tn-atom:hover::after {
                            opacity: 1;
                        }
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030970756'] {
                            top: 320px;
                            left: calc(50% - 480px + 683px);
                            width: 260px;
                            height: 100px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030970756'] {
                            top: 503px;
                            left: calc(50% - 320px + 283px);
                            width: 250px;
                            height: 80px;
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030970756']
                            .tn-atom::before,
                        #rec678565843
                            .tn-elem[data-elem-id='1702030970756']
                            .tn-atom::after {
                            border-radius: 10px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030970756']
                            .tn-atom {
                            font-size: 22px;
                            border-radius: 10px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030970756'] {
                            top: 408px;
                            left: calc(50% - 240px + 234px);
                            width: 206px;
                            height: 60px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702030970756']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702030970756'] {
                            top: 675px;
                            left: calc(50% - 160px + 12px);
                            width: 298px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702031064342'] {
                        color: transparent;
                        background-image: linear-gradient(
                            0.285turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        -webkit-background-clip: text;
                        will-change: transform;
                        z-index: 9;
                        top: 80px;
                        left: calc(50% - 600px + 21px);
                        width: 373px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702031064342']
                        .tn-atom {
                        color: transparent;
                        background-image: linear-gradient(
                            0.285turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        -webkit-background-clip: text;
                        will-change: transform;
                        font-size: 41px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 600;
                        letter-spacing: 2px;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702031064342'] {
                            left: calc(50% - 480px + 11px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702031064342'] {
                            top: 59px;
                            left: calc(50% - 320px + 12px);
                            width: 602px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702031064342'] {
                            width: 470px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702031064342']
                            .tn-atom {
                            font-size: 30px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702031064342'] {
                            width: 291px;
                        }
                    }
                    #rec678565843 .tn-elem[data-elem-id='1702564744813'] {
                        color: #b8b8b8;
                        z-index: 10;
                        top: 210px;
                        left: calc(50% - 600px + 21px);
                        width: 448px;
                    }
                    #rec678565843
                        .tn-elem[data-elem-id='1702564744813']
                        .tn-atom {
                        color: #b8b8b8;
                        font-size: 22px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec678565843 .tn-elem[data-elem-id='1702564744813'] {
                            left: calc(50% - 480px + 11px);
                            width: 382px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec678565843 .tn-elem[data-elem-id='1702564744813'] {
                            top: 136px;
                            left: calc(50% - 320px + 12px);
                            width: 589px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec678565843 .tn-elem[data-elem-id='1702564744813'] {
                            top: 122px;
                            width: 460px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702564744813']
                            .tn-atom {
                            font-size: 18px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec678565843 .tn-elem[data-elem-id='1702564744813'] {
                            top: 145px;
                            width: 297px;
                        }
                        #rec678565843
                            .tn-elem[data-elem-id='1702564744813']
                            .tn-atom {
                            font-size: 16px;
                        }
                    }

                    .t-card__title {
                        color: #ffffff;
                        font-family: 'Arial';
                    }
                    @media screen and (min-width: 480px) {
                        .t-card__title {
                            font-size: 30px;
                        }
                    }
                    .t-card__descr {
                        font-size: 20px;
                        color: #80818c;
                        font-family: 'Arial';
                    }

                    #rec677194115 .t396__artboard {
                        height: 750px;
                        background-color: #101011;
                    }
                    #rec677194115 .t396__filter {
                        height: 750px;
                    }
                    #rec677194115 .t396__carrier {
                        height: 750px;
                        background-position: center center;
                        background-attachment: scroll;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .t396__artboard {
                            height: 690px;
                        }
                        #rec677194115 .t396__filter {
                            height: 690px;
                        }
                        #rec677194115 .t396__carrier {
                            height: 690px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .t396__artboard {
                            height: 520px;
                        }
                        #rec677194115 .t396__filter {
                            height: 520px;
                        }
                        #rec677194115 .t396__carrier {
                            height: 520px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .t396__artboard {
                            height: 580px;
                        }
                        #rec677194115 .t396__filter {
                            height: 580px;
                        }
                        #rec677194115 .t396__carrier {
                            height: 580px;
                            background-attachment: scroll;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .t396__artboard {
                            height: 440px;
                        }
                        #rec677194115 .t396__filter {
                            height: 440px;
                        }
                        #rec677194115 .t396__carrier {
                            height: 440px;
                            background-attachment: scroll;
                        }
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701282926061'] {
                        z-index: 2;
                        top: 374px;
                        left: calc(50% - 600px + 817px);
                        width: 201px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701282926061']
                        .tn-atom {
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701282926061']
                        .tn-atom {
                        -webkit-transform: rotate(338deg);
                        -moz-transform: rotate(338deg);
                        transform: rotate(338deg);
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926061'] {
                            top: 295px;
                            left: calc(50% - 480px + 607px);
                            width: 274px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926061'] {
                            top: 340px;
                            left: calc(50% - 320px + 456px);
                            width: 156px;
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701282926061']
                            .tn-atom {
                            opacity: 0.4;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926061'] {
                            left: calc(50% - 240px + 496px);
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701282926061']
                            .tn-atom {
                            opacity: 0;
                        }
                    }
                    @media screen and (max-width: 479px) {
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701264542001'] {
                        z-index: 3;
                        top: 99px;
                        left: calc(50% - 600px + 20px);
                        width: 1162px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701264542001']
                        .tn-atom {
                        border-radius: 30px;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701264542001']
                        .tn-atom__img {
                        border-radius: 30px;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701264542001'] {
                            top: 179px;
                            left: calc(50% - 480px + 12px);
                            width: 937px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701264542001'] {
                            top: 139px;
                            left: calc(50% - 320px + -126px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701264542001'] {
                            top: 189px;
                            left: calc(50% - 240px + -126px);
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .tn-elem[data-elem-id='1701264542001'] {
                            top: 89px;
                        }
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701282926044'] {
                        color: #ffffff;
                        z-index: 4;
                        top: 126px;
                        left: calc(50% - 600px + 314px);
                        width: 609px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701282926044']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 64px;
                        font-family: 'Spacemono', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926044'] {
                            top: 164px;
                            left: calc(50% - 480px + 34px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926044'] {
                            top: 124px;
                            left: calc(50% - 320px + 25px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926044'] {
                            width: 395px;
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701282926044']
                            .tn-atom {
                            line-height: 1.1;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926044'] {
                            top: 164px;
                            left: calc(50% - 160px + 21px);
                            width: 282px;
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701282926044']
                            .tn-atom {
                            font-size: 30px;
                        }
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701282926059'] {
                        color: #8f00ff;
                        z-index: 5;
                        top: 225px;
                        left: calc(50% - 600px + 314px);
                        width: 488px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701282926059']
                        .tn-atom {
                        color: #8f00ff;
                        font-size: 40px;
                        font-family: 'Spacemono', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 400;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926059'] {
                            top: 263px;
                            left: calc(50% - 480px + 34px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926059'] {
                            top: 223px;
                            left: calc(50% - 320px + 25px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926059'] {
                            top: 272px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926059'] {
                            top: 211px;
                            left: calc(50% - 160px + 21px);
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701282926059']
                            .tn-atom {
                            font-size: 24px;
                        }
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701282926065'] {
                        color: #ffffff;
                        text-align: center;
                        z-index: 7;
                        top: 318px;
                        left: calc(50% - 600px + 314px);
                        width: 297px;
                        height: 68px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701282926065']
                        .tn-atom {
                        color: #ffffff;
                        font-size: 22px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.55;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                        border-width: 0px;
                        border-radius: 100px;
                        background-image: linear-gradient(
                            0.303turn,
                            rgba(143, 0, 255, 1) 0%,
                            rgba(200, 48, 255, 1) 100%
                        );
                        border-color: transparent;
                        border-style: solid;
                        transition: background-color 0.2s ease-in-out,
                            color 0.2s ease-in-out,
                            border-color 0.2s ease-in-out;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926065'] {
                            top: 356px;
                            left: calc(50% - 480px + 34px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926065'] {
                            top: 316px;
                            left: calc(50% - 320px + 25px);
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926065'] {
                            top: 366px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .tn-elem[data-elem-id='1701282926065'] {
                            top: 278px;
                            left: calc(50% - 160px + 21px);
                            width: 279px;
                            height: 60px;
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701282926065']
                            .tn-atom {
                            font-size: 16px;
                        }
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701283046656'] {
                        color: #80818c;
                        z-index: 8;
                        top: 152px;
                        left: calc(50% - 600px + 20px);
                        width: 225px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701283046656']
                        .tn-atom {
                        color: #80818c;
                        font-size: 26px;
                        font-family: 'Arial', Arial, sans-serif;
                        line-height: 1.3;
                        font-weight: 100;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701283046656'] {
                            top: 121px;
                            left: calc(50% - 480px + 34px);
                            width: 814px;
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701283046656'] {
                            top: 81px;
                            left: calc(50% - 320px + 25px);
                            width: 599px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701283046656'] {
                            top: 41px;
                            width: 386px;
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .tn-elem[data-elem-id='1701283046656'] {
                            top: 61px;
                            left: calc(50% - 160px + 21px);
                            width: 289px;
                        }
                        #rec677194115
                            .tn-elem[data-elem-id='1701283046656']
                            .tn-atom {
                            font-size: 20px;
                        }
                    }
                    #rec677194115 .tn-elem[data-elem-id='1701789534747'] {
                        z-index: 9;
                        top: 215px;
                        left: calc(50% - 600px + 708px);
                        width: 160px;
                        height: 3px;
                    }
                    #rec677194115
                        .tn-elem[data-elem-id='1701789534747']
                        .tn-atom {
                        background-color: #8f00ff;
                        background-position: center center;
                        border-color: transparent;
                        border-style: solid;
                    }
                    @media screen and (max-width: 1199px) {
                        #rec677194115 .tn-elem[data-elem-id='1701789534747'] {
                            top: 253px;
                            left: calc(50% - 480px + 428px);
                        }
                    }
                    @media screen and (max-width: 959px) {
                        #rec677194115 .tn-elem[data-elem-id='1701789534747'] {
                            top: 214px;
                        }
                    }
                    @media screen and (max-width: 639px) {
                        #rec677194115 .tn-elem[data-elem-id='1701789534747'] {
                            top: 264px;
                            left: calc(50% - 240px + 31px);
                        }
                    }
                    @media screen and (max-width: 479px) {
                        #rec677194115 .tn-elem[data-elem-id='1701789534747'] {
                            top: 203px;
                            left: calc(50% - 160px + 208px);
                            width: 70px;
                        }
                    }

                    @media screen and (min-width: 981px) {
                        #rec676592663 .t890__arrow:hover svg path {
                            stroke: #ffffff;
                            stroke-width: 1;
                        }
                        #rec676592663 .t890__arrow:focus-visible svg path {
                            stroke: #ffffff;
                            stroke-width: 1;
                        }
                        #rec676592663 .t890__arrow:hover svg rect {
                            fill: #000000;
                            fill-opacity: 1;
                        }
                        #rec676592663 .t890__arrow:focus-visible svg rect {
                            fill: #000000;
                            fill-opacity: 1;
                        }
                    }
                    #rec676592663 .t890__arrow {
                        border-radius: 53px;
                    }

                        #rec677196290 .t977__descr {
                            color: #ffffff;
                        }
