@import 'assets/css/config';
@import 'assets/fonts/Mont/stylesheet';

html {
    scroll-behavior: smooth;
}

body {
    background: $black !important;
    color: $white !important;
    font-family: $light !important;

    position: static !important;
    padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8 {
    margin: 0;
    font-family: $semibold;
    line-height: 130%;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
    line-height: 140%;
}

h6 {
    font-size: 18px;
    line-height: 140%;
}

h7 {
    font-size: 16px;
    line-height: 140%;
}

h8 {
    font-family: $regular;
    font-size: 14px;
    line-height: 160%;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    display: block;
}

svg {
    display: block;
}
