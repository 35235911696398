@import 'assets/css/config';

.container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14px;
    line-height: 160%;
}

.tag {
    padding: 4px 15px;

    &.active:after,
    &.active:before {
        background: $purpure;
    }
}
