@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px;
    width: 100%;
    max-width: 1240px;
}

.title {
    align-self: flex-start;
    background: $white-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.judge {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 240px;
}

.judgeImage {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.judgeInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.judgeAbout {
    font-size: 16px;
    line-height: 160%;
    text-align: center;
}

@media (max-width: 576px) {
    .judge {
        width: 190px;
    }

    .judgeImage {
        width: 130px;
        height: 130px;
    }

    .judgeName {
        font-size: 14px;
    }

    .judgeAbout {
        font-size: 12px;
    }
}
