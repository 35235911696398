.cases {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
  max-width: calc(1200px - 40px);
  margin: 0 auto;
  padding: 0 20px;
}

.cases p {
  margin: 0;
}

.case {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 32px;
  width: calc(100% / 2 - 25px);
  border-radius: 10px;
  border: 1px solid #450975;
  box-sizing: border-box;
}

.case__main {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.case__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.case__name {
  color: #fff;

  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.case__description {
  color: #b8b8b8;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.case__btn {
  align-self: flex-start;
  padding: 15px 20px;
  border-radius: 54px;
  background: #8f00ff;
  color: #fff !important;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  margin-top: auto !important;
}

.case__logo {
  width: 239px;
  max-width: 100%;
}

.case__separator {
  width: 366px;
  max-width: 100%;
}

.modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  visibility: hidden;
  opacity: 0;
  z-index: 999999;
}

.modal.active {
  visibility: visible;
  opacity: 1;
}

.modal__overlay {
  background: rgba(16, 16, 17, 0.5);
  backdrop-filter: blur(3px);
  position: fixed;
  inset: 0;
  z-index: -1;
}

.modal__content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 32px;
  box-sizing: border-box;
  width: 860px;
  max-width: 90vw;
  max-height: 80vh;
  background: #242227;
  border-radius: 10px;
  overflow: auto;
}

.modal__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.modal__logo {
  width: 239px;
  max-width: 100%;
}

.modal__close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.modal__block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal__block-title {
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal__block-description {
  color: #b8b8b8;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1200px) {
  .cases {
    max-width: calc(960px - 40px);
  }
}

@media screen and (max-width: 960px) {
  .cases {
    max-width: calc(640px - 40px);
  }
}

@media screen and (max-width: 600px) {
  .cases {
    max-width: calc(100% - 40px);
  }

  .case {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .cases {
    padding: 0 10px; 
  }
}
