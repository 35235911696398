@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 20px;
    width: 100%;
    max-width: 1240px;
}

.title {
    align-self: flex-start;
    background: $white-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.fund {
    display: flex;
    justify-content: center;
    gap: 100px;
}

.fundItem {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.fundItemMain {
    background: $blue-purpure;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 64px;
    font-family: $semibold;
    line-height: 130%;
}

.fundItemHint {
    font-size: 20px;
    line-height: 160%;
}

.benefits {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.benefitsItems {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.benefitsItem {
    display: flex;
    gap: 16px;
    padding: 24px;
    width: calc((100% - 24px) / 2);
    border: 1px solid $purpure;
    border-radius: 24px;
}

.benefitsItemIcon {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
}

.benefitsItemContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    line-height: 160%;
    white-space: pre-line;
}

@include screen-lg {
    .fundItemMain {
        font-size: 30px;
    }
}

@include screen-sm {
    .fund {
        flex-direction: column;
        gap: 20px;
    }

    .fundItem {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    .fundItemMain {
        font-size: 24px;
    }

    .benefitsItems {
        flex-wrap: nowrap;
        overflow: auto;
        margin: 0 -20px;
        padding: 0 20px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .benefitsItem {
        flex-direction: column;
        flex-shrink: 0;
        width: 290px;
    }
}
