@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 10;
    transition: $transition;

    &.active {
        top: 0;
        opacity: 1;
    }
}

.backdrop {
    flex-grow: 1;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px 16px;
    max-height: 75%;
    overflow: auto;
    background: $shadow;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px -10px 16px rgba(63, 59, 110, 0.5);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    path {
        stroke: $white;
    }
}
