@import 'assets/css/config';

.container {
    display: flex;
    flex-direction: column;
    gap: calc(24px * 2);
    position: relative;
    margin: 0 auto;
    padding: 24px 20px;
    max-width: 1240px;
    width: 100%;
    color: $dark-purpure;
    font-size: 16px;
    line-height: 160%;
    text-align: center;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc((100% - 100vw) / 2);
        width: 100vw;
        height: 1px;
        background: $purpure;
    }
}

.block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child):after {
        content: '';
        position: absolute;
        top: calc(-24px - 0.5px);
        left: 0;
        right: 0;
        height: 1px;
        background: $shadow;
    }
}

.links {
    display: flex;
    gap: 50px;

    &.long {
        gap: 67px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.socialNetworks {
    display: flex;
    gap: 10px;

    path {
        stroke: $dark-purpure;
    }
}

@include screen-lg {
    .block {
        flex-direction: column;
        gap: 16px;

        &:last-child {
            flex-direction: column-reverse;
        }
    }

    .contacts {
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }
}

@include screen-xs {
    .links,
    .links.long {
        flex-direction: column;
        gap: 16px;
    }
}
